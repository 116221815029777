:root {
  --color-background: #e5e5e5;

  --light-color-transparent: transparent;
  --dark-color-transparent: transparent;

  --light-color-neutral-white: #ffffff;
  --dark-color-neutral-white: #171f35;
  --color-black: #000000;

  /*Grays Light-Dark*/
  --light-color-gray-100: #fafbfd;
  --dark-color-gray-100: #1e273f;
  --light-color-gray-200: #f3f5f9;
  --dark-color-gray-200: #02091b;
  --light-color-gray-300: #e4e7ec;
  --dark-color-gray-300: #e4e7ec;
  --light-color-gray-400: #d4dae7;
  --dark-color-gray-400: #3f4965;
  --light-color-gray-500: #b2b9c9;
  --dark-color-gray-500: #7480a0;
  --light-color-gray-600: #7f8797;
  --dark-color-gray-600: #929dbb;
  --light-color-gray-700: #142653;
  --dark-color-gray-700: #ffffff;

  /*Primary Color Light-Dark*/
  --light-color-blue-100: #ecf9ff;
  --dark-color-blue-100: #0d2446;
  --light-color-blue-200: #3ca7ff;
  --dark-color-blue-200: #1d436c;
  --light-color-blue-300: #40a9ff;
  --dark-color-blue-300: #59b4ff;
  --light-color-blue-400: #118dff;
  --dark-color-blue-400: #45a6ff;
  --light-color-blue-500: #0077c8;
  --dark-color-blue-500: #096dd9;

  /*Red Light-Dark*/
  --light-color-red-100: #fff1f0;
  --dark-color-red-100: #3d1815;
  --light-color-red-200: #ffa39e;
  --dark-color-red-200: #5a1f1c;
  --light-color-red-300: #ff7875;
  --dark-color-red-300: #ff7875;
  --light-color-red-400: #ff4d4f;
  --dark-color-red-400: #ff4d4f;
  --light-color-red-500: #f5222d;
  --dark-color-red-500: #ff333e;

  /*Green Light-Dark*/
  --light-color-green-100: #f6ffed;
  --dark-color-green-100: #101c04;
  --light-color-green-200: #b7eb8f;
  --dark-color-green-200: #183800;
  --light-color-green-300: #73d13d;
  --dark-color-green-300: #73d13d;
  --light-color-green-400: #52c41a;
  --dark-color-green-400: #52c41a;
  --light-color-green-500: #389e0d;
  --dark-color-green-500: #389e0d;

  /*Orange Light-Dark*/
  --light-color-orange-100: #fff7e6;
  --dark-color-orange-100: #2c2008;
  --light-color-orange-200: #ffd591;
  --dark-color-orange-200: #482d00;
  --light-color-orange-300: #fa8c16;
  --dark-color-orange-300: #fa8c16;

  /*Yellow Light-Dark*/
  --light-color-yellow-100: #fffbe3;
  --dark-color-yellow-100: #231e01;
  --light-color-yellow-200: #ffee96;
  --dark-color-yellow-200: #4b3f00;
  --light-color-yellow-300: #f9c200;
  --dark-color-yellow-300: #f9c200;

  /*Link Color Light-Dark*/
  --light-color-link-100: #096dd9;
  --dark-color-link-100: #096dd9;

  --space-160: 160px;
  --space-64: 64px;
  --space-60: 60px;
  --space-48: 48px;
  --space-40: 40px;
  --space-32: 32px;
  --space-36: 36px;
  --space-24: 24px;
  --space-20: 20px;
  --space-16: 16px;
  --space-12: 12px;
  --space-8: 8px;
  --space-4: 4px;

  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-10: 10px;
  --border-radius-12: 12px;
  --border-radius-24: 24px;

  --light-box-shadow-4: 0 2px 4px rgba(0, 0, 0, 0.2);
  --light-box-shadow-8: 0 0 12px rgba(29, 68, 168, 0.1);
  --light-box-shadow-12: 0 4px 20px rgba(29, 68, 168, 0.16);

  --dark-box-shadow-4: 0 2px 4px rgba(0, 0, 0, 0.2);
  --dark-box-shadow-8: 0 0 8px rgba(0, 0, 0, 0.3);
  --dark-box-shadow-12: 0 4px 12px rgba(0, 0, 0, 0.3);
}

:root {
  --font-inter: "Inter";
  --font-brand: var(--font-inter);
}
