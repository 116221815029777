.calendar {
  border: none;

  div:nth-child(2) > div:nth-child(1) {
    background-color: transparent;
    border-bottom: none;
  }
}

.week-day {
  width: 28px;
  height: 28px;
  font-size: 16px;
  font-family: var(--font-brand), sans-serif;
  font-weight: 600;
  color: var(--color-gray-600);
  margin: 4px;
}

.day {
  width: 28px;
  height: 28px;
  font-family: var(--font-brand), sans-serif;
  font-size: 11px;
  font-weight: 600;
  margin: 4px;
  color: var(--color-gray-600);
}

.day:hover {
  border-radius: 50% !important;
}

.selected-day {
  background-color: var(--color-brand-blue) !important;
  color: white !important;
  border-radius: 50% !important;

  .day-content {
    .dot-container {
      .dot {
        background-color: white !important;
      }
    }
  }
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: var(--color-brand-blue) !important;
}
