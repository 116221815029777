:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --color-gray-900: #111111;
  --color-gray-800: #333333;
  --color-gray-700: #282a30;
  --color-gray-600: #707787;
  --color-gray-500: #a4abb5;
  --color-gray-400: #bdbdbd;
  --color-gray-300: #cccccc;
  --color-gray-200: #f3f5f9;
  --color-gray-100: #f5f5f5;
  --color-primary-blue-200: #3ca7ff;
  --color-brand-blue: #0077c8;
  --color-blue: #0058a3;
  --color-blue-100: #edf9ff;
  --color-blue-200: #3ca7ff;
  --color-cian: #4595ff;
  --color-yellow: #ffdb00;
  --color-yellow-100: #fffbe3;
  --color-yellow-200: #F4CF0D;
  --color-orange: #ffa524;
  --color-green: #0a8a00;
  --color-green-100: #f3f9ee;
  --color-green-200: #7ad144;
  --color-red: #cc0008;
  --color-red-100: #fff1f0;
  --color-red-200: #ff7875;
  --color-orange-100: #fff7e6;
  --color-orange-200: #ffbb4e;
  --color-pink: #e00751;
  --color-brand: var(--color-brand-blue);
  --color-error: var(--color-pink);
  --color-warning: var(--color-orange);
  --color-button-primary: var(--color-brand);
  --color-button-secondary: var(--color-black);
}
