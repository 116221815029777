@font-face {
    font-family: 'Inter';
    src: local('Inter Thin'), local('Inter-Thin'),
    url('../fonts/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}@font-face {
    font-family: 'Inter';
    src: local('Inter ExtraLight'), local('Inter-ExtraLight'),
    url('../fonts/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter Light'), local('Inter-Light'),
    url('../fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter Regular'), local('Inter-Regular'),
    url('../fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter Medium'), local('Inter-Medium'),
    url('../fonts/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter SemiBold'), local('Inter-SemiBold'),
    url('../fonts/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter Bold'), local('Inter-Bold'),
    url('../fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
    url('../fonts/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: local('Inter Black'), local('Inter-Black'),
    url('../fonts/Inter-Black.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

